import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import "../header/header.scss"

const Header = ({title, image, accent}) => {

    return (
        <div className="header">
            <img src={image || "/assets/placeholder.png"} alt="Header" className="headerTop" />
            <div className="headerBottom">
                <div className="top" style={{background: `rgba(${accent}, 0.7)`}}>
                    <button>
                        <ExpandMoreOutlinedIcon />
                    </button>
                    <Link to="/de/">Studio G</Link>
                </div>
                <div className="bottom" style={{background: `rgba(${accent})`}}>
                    <h1>{title}</h1>
                </div>
            </div>
        </div>
    )
};

export default Header