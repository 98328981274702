import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import Header from "../../../components/header/header";
import axios from "axios";
import "./viewDienstleistungen.scss";

const ViewDienstleistungen = () => {
    const { t, i18n } = useTranslation();

    const [dienstleistungen, setDienstleistungen] = useState(null); // Initialize as null
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    const location = useLocation();
    const dienstleistungenSlug = location.pathname.split("/")[3];

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Dienstleistungen
                const dienstleistungenRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/dienstleistungen?slug=${dienstleistungenSlug}`);
                if (dienstleistungenRes.data && dienstleistungenRes.data.length > 0) {
                    setDienstleistungen(dienstleistungenRes.data[0]); // Set to first item if array is returned
                } else {
                    setDienstleistungen(null); // Set to null if no data
                }
            } catch (err) {
                console.log(err);
                setDienstleistungen(null); // Set to null in case of error
            } finally {
                setLoading(false); // Ensure loading is stopped in all cases
            }
        };

        fetchAllRef();
    }, [lang, dienstleistungenSlug]);

    usePageName(isLoading ? `${t("site.loading")} | ${t("site.pageName")}` : `${dienstleistungen.title.rendered || ''} | ${t("site.pageName")}`);

    return (
        <div className="viewDienstleistungen">
            {isLoading ? (
                <div className="loading" />
            ) : (
                <div className="content">
                    <Header title={dienstleistungen.title.rendered} image={dienstleistungen.acf.image} accent={dienstleistungen.acf.bereich} />
                    <div className="container">
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: dienstleistungen.content.rendered }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewDienstleistungen;
